import React from "react";

import Layout from "../components/Layout";

import ProduitsSVG from "../assets/produits.svg";
import MovecoSVG from "../assets/moveco.svg";
import DotterJPG from "../assets/dotter.jpg";

import Moveco1 from "../assets/MOVECO_1.png";
import Moveco2 from "../assets/MOVECO_2.png";

function Produits() {
  return (
    <Layout title="Produits">
      <h2 className="text-4xl font-bold leading-none text-center lg:text-6xl">
        KerNel for <span className="text-orange-500">Clinics</span>
      </h2>
      <section className="container px-6 mx-auto">
        <div className="relative w-full p-4 mx-auto">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
            <div className="lg:col-start-2 md:pl-20">
              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-orange-500 rounded-md">
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="w-6 h-6"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Nos produits
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>
                          Collecte et management des données de recherche
                          clinique :{" "}
                          <span className="font-bold">Dotter.science</span>
                        </li>
                        <li>
                          Télémonitoring des patients ventilés :{" "}
                          <span className="font-bold">MOVECO</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-orange-500 rounded-md">
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="w-6 h-6"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Pour qui ?
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>Chercheurs et professionnels de santé</li>
                        <li>
                          Sociétés de biotechnologies, laboratoires et CROs
                        </li>
                        <li>Établissements de santé</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
              <ProduitsSVG className="relative w-1/2 mx-auto" />
            </div>
          </div>
        </div>
      </section>
      <div className="bg-coolGray-100 text-coolGray-800">
        <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              Dotter EDC (Electronic Data Capture)
            </h2>
            <p className="mt-4 text-lg text-coolGray-600">
              Dotter.science est une plateforme en ligne permettant d’optimiser
              la conception, la saisie et le management électronique des données
              de recherche clinique
            </p>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Des cahiers de recueil électronique (eCRF) clairs, dynamiques
                  et simple à concevoir sur l'interface en ligne{" "}
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Des outils de data-managements intégrés avec une gestion des
                  rôles utilisateurs
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Un outil ergonomique et accessible depuis n'importe quel PC,
                  tablette ou smartphone
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Des données sécurisées grâce aux serveurs HDS et à la
                  pseudonymisation des données
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Un outil développé par des chercheurs, pour des chercheurs
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Interactions facilités entre les intervenants de l'essai
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Données de qualité grâce à la vérification temps-réel
                </dt>
              </div>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-orange-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <div className="ml-3">
                <dt className="text-lg font-medium">
                  Économies de papier et expérience utilisateur optimisée
                </dt>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <img
        src={DotterJPG}
        className="w-full max-w-screen-sm mx-auto rounded shadow-2xl md:w-auto lg:max-w-screen-md"
        alt=""
      />
      <section className="p-4 text-center md:p-8 bg-coolGray-100 text-coolGray-800">
        <div className="container p-4 mx-auto my-6 space-y-2">
          <h2 className="text-5xl font-bold">Exemples de use-cases</h2>
        </div>
        <div className="container grid justify-center gap-4 mx-auto sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col items-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 text-orange-600"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h3 className="my-3 text-3xl font-semibold">APHP</h3>
            <div className="space-y-1 leading-tight">
              <p>
                Étude sur l'impact du statut socio-économique des patients
                hospitalisés en raison de la Covid-19 en région parisienne
              </p>
              <p>
                <a
                  className="text-blue-500 underline"
                  href="https://dotter.science/2020/12/08/comment-dotter-a-permis-la-realisation-eclair-dune-etude-multicentrique-pendant-lepidemie-de-covid-19/"
                  target="blank"
                >
                  En savoir plus
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 text-orange-600"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h3 className="my-3 text-3xl font-semibold">Asten Santé</h3>
            <div className="space-y-1 leading-tight">
              <p>
                Évaluation de la qualité de vie du (de la) conjoint(e) des
                patients appareillés au long cours pour une pathologie
                respiratoire au domicile
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 text-orange-600"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h3 className="my-3 text-3xl font-semibold">ADIR Association</h3>
            <div className="space-y-1 leading-tight">
              <p>
                Prévalence et conséquences de l'incontinence urinaire chez les
                patients adressés en réhabilitation respiratoire
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 text-orange-600"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h3 className="my-3 text-3xl font-semibold">CHU de Rouen</h3>
            <div className="space-y-1 leading-tight">
              <p>
                Efficacité d’un mode ventilatoire en pression avec volume cible
                vs. un mode en pression dans la ventilation non invasive des
                patients à risque d'hypoventilation ou d'apnées obstructives
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 text-orange-600"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h3 className="my-3 text-3xl font-semibold">Fédération ANTADIR</h3>
            <div className="space-y-1 leading-tight">
              <p>
                Étude de l'efficacité d'un dispositif médical innovant comparée
                à une technique traditionnelle
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6 bg-orange-100 text-coolGray-900">
        <div className="container flex flex-col items-center justify-center p-4 mx-auto space-y-8 md:p-10 lg:space-y-0 lg:flex-row lg:justify-between">
          <h1 className="text-3xl font-semibold leading-tight text-center lg:text-left">
            Si Dotter vous intéresse, rendez vous sur le site internet :
          </h1>
          <a href="https://dotter.science/" target="blank">
            <button className="px-8 py-3 text-lg font-semibold bg-orange-600 rounded text-coolGray-50">
              Se rendre sur le site internet
            </button>
          </a>
        </div>
      </section>
      <h2 className="mt-12 mb-4 text-3xl font-extrabold leading-none text-center sm:text-4xl">
        MOVECO (MOnitoring of VEntilation for COvid-19 patients)
      </h2>
      <section className=" text-coolGray-800">
        <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-around">
          <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-md lg:text-left">
            <h1 className="text-3xl font-bold leading-none sm:text-4xl">
              Un outil de <span className="text-orange-500">monitoring</span>{" "}
              des données ventilatoires{" "}
              <span className="text-orange-500">centralisé</span>
            </h1>
            <p className="mt-6 mb-8 text-lg sm:mb-12">
              La plateforme MOVECO permet de{" "}
              <span className="text-orange-500">monitorer à distance</span> des
              patients équipés de dispositifs d'oxymétrie, de pression positive
              continue et/ou ventilés, en situation hospitalière ou depuis leur
              domicile.{" "}
            </p>
          </div>
          <div className="flex items-center justify-center w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
            <MovecoSVG className="object-cover object-center" />
          </div>
        </div>
      </section>
      <section className="p-4 lg:p-8 bg-coolGray-100 text-coolGray-800">
        <div className="container mx-auto space-y-12">
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
            <div className="flex items-center justify-center flex-1 bg-orange-600">
              <iframe
                className="w-full h-full"
                src="https://www.youtube.com/embed/B1EXgTDxodM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="flex flex-col justify-center flex-1 px-6 py-12 bg-coolGray-50">
              <h2 className="text-3xl font-bold">
                Origine et description du projet
              </h2>
              <p className="my-6 text-coolGray-600">
                Afin de venir en aide à l'hôpital Avicenne (groupe AP-HP,
                Bobigny) dont les capacités en lits de réanimation étaient
                épuisées au cours de l’épidémie de COVID-19, nous avons
                développé une solution embarquée permettant aux professionnels
                de santé de monitorer en temps réel les données respiratoires de
                plusieurs patients hospitalisés au sein d'unités de soins
                intermédiaires créées pour l’occasion et traités avec des
                ventilateurs de domicile.
              </p>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse">
            <div className="flex items-center justify-center flex-1 bg-orange-600">
              <img src={Moveco2} alt="" className="w-full h-full" />
            </div>
            <div className="flex flex-col justify-center flex-1 px-6 py-12 bg-coolGray-50">
              <h2 className="text-3xl font-bold">
                Les valeurs mesurées en temps réel, avec possibilité de régler
                des alarmes personnalisées, étaient{" "}
              </h2>
              <ul className="my-6 text-coolGray-600">
                <li>la fréquence cardiaque</li>
                <li>la fréquence respiratoire</li>
                <li>la SpO2</li>
                <li>le volume inspiratoire</li>
                <li>la pression dans les voies aériennes</li>
                <li>le débit de fuite</li>
                <li>les asynchronismes patient-ventilateur</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
            <div className="flex items-center justify-center flex-1 bg-orange-600">
              <img src={Moveco1} alt="" className="w-full h-full" />
            </div>
            <div className="flex flex-col justify-center flex-1 px-6 py-12 bg-coolGray-50">
              <h2 className="text-3xl font-bold">Caractère innovant</h2>
              <p className="my-6 text-coolGray-600">
                La solution développée devait avoir la capacité d’acquérir en
                temps réel les données des ventilateurs de domicile, ce afin
                d’émettre des alarmes spécifiques et notamment des alarmes sur
                le niveau de fuites, qui reflète la désadaptation du masque ou
                la déconnexion du patient de sa machine, potentiellement létale.
              </p>
              <p className="my-6 text-coolGray-600">
                Une dépêche de{" "}
                <a
                  className="text-blue-500 underline"
                  href="https://www.bfmtv.com/sante/coronavirus-a-bobigny-une-unite-inedite-creee-en-quelques-jours-par-des-soignants_AN-202004150268.html"
                  target="blank"
                >
                  l’AFP
                </a>{" "}
                et des journaux nationaux notamment à caractère économique se
                sont fait écho de la mise en place de cette unité de soins
                intensifs respiratoires innovante, qui a par ailleurs été
                présentée en congrès scientifique (
                <a
                  className="text-blue-500 underline"
                  href="https://europepmc.org/article/pmc/pmc7796852"
                  target="blank"
                >
                  résumé de communication ici
                </a>
                ).
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="px-12 py-10 bg-orange-100 text-coolGray-900">
        <p className="text-2xl font-semibold leading-tight text-center">
          À ce jour, la solution MOVECO doit disposer du marquage CE pour
          pouvoir être utilisée, aussi bien en milieu hospitalier qu'à domicile.
          N'hésitez pas à nous contacter pour toute question ou projet de
          partenariat.
        </p>
      </section>
    </Layout>
  );
}

export default Produits;
